


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BilanzTableItem from '@/components/common/BilanzTableItem.vue';
import { getFormattedGermanNum } from '../../helper/utils';
import store from '../../store';

@Component({components: { BilanzTableItem }})
export default class BilanzTable extends Vue {
    @Prop() data!: any
    @Prop() hideBilanzRows!: any

    get passivaPreviousValue(){
        return this.data.body.prev && this.data.body.prev[1] ? this.data.body.prev[1].value : 0;
    }

    get passivaCurrentValue(){
        return this.data.body.current && this.data.body.current[1] ? this.data.body.current[1].value : 0;
    }

    get aktivaPreviousValue(){
        return this.data.body.prev && this.data.body.prev[0] ? this.data.body.prev[0].value : 0;
    }

    get aktivaCurrentValue(){
        return this.data.body.current && this.data.body.current[0] ? this.data.body.current[0].value : 0;
    }

    get currentYear() {
        return store.getters.selectedYear
    }

    getFormattedNumber(val:any){
       return getFormattedGermanNum(val);
    }
}
