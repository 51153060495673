




















import { Component, Prop, Vue } from "vue-property-decorator";
import { getFormattedGermanNum, germanZahl } from '../../helper/utils';

@Component({
    name: "BilanzTableItem"
})
export default class BilanzTableItem extends Vue {
    @Prop() item!: any
    @Prop() type!: any
    @Prop() prevItem!: any
    @Prop() showPrev!: any
    @Prop() hideBilanzRows!: any

    get prevItemValue(){
        return this.prevItem && this.prevItem.value || 0;
    }
    get currentItemValue(){
        return this.item && this.item.value || 0;
    }

    getFormattedNumber(val:any){
       return getFormattedGermanNum(val);
    }

    getGermanZahl(val:any){
       return germanZahl(val);
    }
}
