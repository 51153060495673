





















import TopBar from '@/components/common/TopBar.vue';
import Widget from '@/components/common/Widget.vue';
import BilanzTable from '@/components/table/BilanzTable.vue';
import Vue from 'vue'
import store from '../../store';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { callCalc, callPrevCalc } from '../../helper/utils';

@Component({components: {TopBar, Widget, BilanzTable}})
export default class Bilanz extends Vue
{
  public hideBilanzRows: boolean = false;
  initPage = false;

  get bilanz() {
    let prevData = store.getters.previousCalcs && store.getters.previousCalcs.BILANZ && store.getters.previousCalcs.BILANZ.items;
    let currentData = store.getters.currentCalcs && store.getters.currentCalcs.BILANZ && store.getters.currentCalcs.BILANZ.items;
    return {
      body: {
        current: currentData,
        prev: prevData
      }
    }
  }

  mounted() {
    this.initPage = true;
    this.onTopBarDataChange()
  }

  get topBarData() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient
    }
  }

  get topBarNavigation() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient
    }
  }

  get biAgency() {
      return store.getters.selectedBiAgency
  }

  pushStateHistory(stb:any,mandant:any, year:any) {
    const selectedStb = (this.biAgency != null) ? this.biAgency : stb
    let path = '/' + this.$route.name 
      + '/' + encodeURIComponent(selectedStb)
      + '/' + encodeURIComponent(mandant)
      + '/' + encodeURIComponent(year)

    if (this.$route.path !== path) {
      this.$router.replace(path)
    }
  }

  @Watch('topBarNavigation')
  onTopBarNavigationChange() {
    // change URL based on seleted parameters in top bar
    let mandant: string = this.topBarNavigation.client[1]
    let stb : string = this.topBarNavigation.client && this.topBarNavigation.client[0]
    let year: string = this.topBarNavigation.year[0]
    this.pushStateHistory(stb, mandant, year)
  }

  @Watch('topBarData') 
  onTopBarDataChange() {
    if (this.topBarData.year && this.topBarData.client) {
      if (!store.getters.isCalcCalled) {
        callCalc()
      }
      if (!store.getters.isPrevCalcCalled) {
        callPrevCalc()
      }
      
      if (this.initPage) {
        this.onTopBarNavigationChange()
        this.initPage = false;
      }
    }
  }
}
